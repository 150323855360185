import React from "react";
import PageInfo from "../../components/PageInfo";

import image from "../../assets/images/BasisKursus.jpeg";

export default function TrePaaBaandLektion() {
	return (
		<>
			<p>
				Er I 3 venner, der bare gerne vil komme en gang imellem og stå på ski,
				men har I ikke tid eller mod til at melde jer på et teknikkursus? Så har
				vi også muligheden med en “3 på båndet” pris. Bestiller i samlet, kan I
				få jeres eget “hold”.
			</p>
		</>
	);
}
